import { noticeError } from '../../../services/Monitoring'
import { extratosApi } from '../../../webClient'
import { DuplicatedLancamentos } from './sendExtrato'

export interface SendToVistaExtractBody {
  companyId: string
  familyId: string
  paymentMethodId: number
  spreadsheetBase64: string
}

export interface SendVistaExtractResponse {
  duplicated: DuplicatedLancamentos[]
  inserted: number
  notFoundBudgetorCategory: boolean
  withoutCategory: ExtractWithoutCategory[]
}

export interface ExtractWithoutCategory {
  id: number
  absoluteValue: number
  valor: number
  data: string
  accountId: number | string | null
  agrupamento: number | string | null
  ativoId: number | null
  categoriaId: number | null
  corretagem: number | null
  corretoraId: number | null
  cotizadoCorretamente: boolean
  custoOperacional: number | string | null
  dataVencimento: number | string | null
  descricao: string | null
  emolumentos: number | null
  empresaId: number | null
  familiaId: number | null
  idInstituicaoFinanceira: number | string | null
  idMovimentacao: number | string | null
  idTransacaoBob: number | string | null
  impostoRenda: number | string | null
  iof: number | string | null
  meioPagamentoId: number | null
  nomeCategoria: number | string | null
  nomeMeioPagamento: number | string | null
  nomeOrcamento: number | string | null
  operacao: number | string | null
  orcamentoId: number | null
  parcelas: number | null
  patrimonioAtivoId: number | string | null
  patrimonioId: number | string | null
  precoCota: number | string | null
  quantidade: number | null
  statusCategorizacaoBob: number
  taxaAnual: number | string | null
  taxaCustomizada: number | string | null
  tipoDeParcela: number | string | null
  tipoMeioPagamento: number | string | null
  tipoOrcamento: number | string | null
  tipoRetirada: number | string | null
  valorParcela: number | null
}

export const postSendVistaExtract = async (body: SendToVistaExtractBody) => {
  // const url = '/lancamento/extratos/upload'
  // const { status, data: response } = await apiWebClient.post<SendVistaExtractResponse>(url, body)
  const { status, data: response } = await extratosApi.post<SendVistaExtractResponse>('/v2/extrato', body)

  if (status !== 200) {
    const { companyId, familyId, paymentMethodId, spreadsheetBase64 } = body
    const err = new Error('Erro ao enviar extratos.')
    noticeError(err, { companyId, familyId, paymentMethodId, spreadsheetBase64 })
    throw err
  }

  return response
}
