import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { TDateRangePickerActionsMapKeys } from '../../components/Mui/DatePicker'
import { logger } from '../logger/logger'

interface ExtractStoreV2 {
  searchFilter?: string
  paymentMethodId?: number
  budgetId?: number
  type?: string
  categoryId?: number
  startDate?: string
  endDate?: string
  dateFilterMonths?: TDateRangePickerActionsMapKeys
}

export interface SetStore {
  set: {
    paymentMethodId: (paymentMethodId: number) => void
    budgetId: (budgetId: number) => void
    type: (type: string) => void
    categoryId: (categoryId: number) => void
    searchFilter: (searchFilter: string) => void
    startDate: (startDate: string) => void
    endDate: (endDate: string) => void
    dateFilterMonths: (dateFilterMonths: TDateRangePickerActionsMapKeys) => void
  }
  reset: () => void
}

export type TSetStoreKeys = keyof SetStore['set']

const initialState: ExtractStoreV2 = {
  searchFilter: '',
  paymentMethodId: undefined,
  budgetId: undefined,
  type: undefined,
  categoryId: undefined,
  startDate: undefined,
  endDate: undefined,
  dateFilterMonths: undefined
}

export type ExtractStoreV2Keys = keyof ExtractStoreV2

export const useExtractStoreV2 = create<ExtractStoreV2 & SetStore>()(
  persist(
    logger<ExtractStoreV2 & SetStore>((set) => {
      const setSearchFilter = (searchFilter?: string) => set({ searchFilter })
      const setPaymentMethodId = (paymentMethodId?: number) => set({ paymentMethodId })
      const setBudgetId = (budgetId?: number) => set({ budgetId })
      const setType = (type?: string) => set({ type })
      const setCategoryId = (categoryId?: number) => set({ categoryId })
      const setStartDate = (startDate?: string) => set({ startDate })
      const setEndDate = (endDate?: string) => set({ endDate })
      const setDateFilterMonths = (dateFilterMonths?: TDateRangePickerActionsMapKeys) =>
        set({ dateFilterMonths })
      const reset = () => set(initialState)

      return {
        ...initialState,
        set: {
          paymentMethodId: setPaymentMethodId,
          budgetId: setBudgetId,
          type: setType,
          categoryId: setCategoryId,
          searchFilter: setSearchFilter,
          startDate: setStartDate,
          endDate: setEndDate,
          dateFilterMonths: setDateFilterMonths
        },
        reset: reset
      }
    }, 'extract-store'),
    {
      name: 'extract-store',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
)
