import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import React, { useState } from 'react'

import { useBankPdfTypes } from '../../queries/extrato/queries'
import { DownloadExcelDefault } from '../Buttons'
import { ButtonGradient } from '../Mui/Button'
import { FormExtractUpload } from './FormExtractUpload'
import { ModalUpload } from './ModalUpload'

export const UploadExtratoV2 = ({ onSuccess }: { onSuccess: () => void }) => {
  const [banco] = useState<string>()
  const [typePdf, setTypesPdf] = useState<string>()
  const [year, setYear] = useState<number>()
  const typesPDF = useBankPdfTypes(banco)
  const [extractMenuIsOpen, setExtractMenuIsOpen] = useState(false)

  const displayPdfTypes = typesPDF && typesPDF.length > 0
  if (!displayPdfTypes && typePdf) setTypesPdf(undefined)

  const displayYearDropdown = banco?.toLowerCase()?.startsWith('btg')
  if (!displayYearDropdown && year) setYear(undefined)

  return (
    <>
      <DownloadExcelDefault />

      <ButtonGradient
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        onClick={() => {
          setExtractMenuIsOpen(!extractMenuIsOpen)
        }}
      >
        Upload de Extrato
      </ButtonGradient>

      <ModalUpload isOpen={extractMenuIsOpen} onClose={() => setExtractMenuIsOpen(false)}>
        <FormExtractUpload
          extractMenuIsOpen={extractMenuIsOpen}
          onSuccess={() => {
            setExtractMenuIsOpen(false)
            onSuccess?.()
          }}
        />
      </ModalUpload>
    </>
  )
}
