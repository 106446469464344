import { Box, Divider, Typography } from '@mui/material'
import { format } from 'date-fns'
import React from 'react'

import { Resume } from '../../domain/extrato/api/sendExtrato'
import { AlertDialogSlide } from '../Mui/Modal'
import { formatCurrencyBRL } from '../Utils'

interface Props {
  isOpen: boolean
  onAccept: () => void
  resume?: Resume
  onDismiss?: () => void
  isLoading?: boolean
  exception?: {
    id: number
    date: string
    value: number
    description: string
  }[]
  errorMsg?: string
}

export const DialogExtractUploadSuccess = ({ isOpen, onAccept, exception, errorMsg }: Props) => {
  return (
    <AlertDialogSlide
      isOpen={isOpen}
      onClose={onAccept}
      title="Upload de extrato"
      agreeButton={{
        text: 'Entendi',
        onClick: onAccept
      }}
    >
      <Box display="flex" flexDirection="column" gap={3}>
        {errorMsg && <Typography variant="body1">{errorMsg}</Typography>}

        {exception && exception?.length > 0 && (
          <Box>
            {exception.map((item) => (
              <Box key={item.id} display="flex" gap={2}>
                <Typography variant="body1" sx={{ fontSize: '0.8rem', padding: '0.5rem' }}>
                  {format(new Date(item.date), 'dd/MM/yyyy')}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: '0.8rem', padding: '0.5rem', maxWidth: '200px', width: '100%' }}
                >
                  {item.description}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '0.8rem', padding: '0.5rem' }}>
                  {formatCurrencyBRL(item.value)}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </AlertDialogSlide>
  )
}

export const DialogExtractResume = ({
  resume,
  isOpen,
  onAccept,
  onDismiss,
  isLoading
}: Pick<Props, 'resume' | 'isOpen' | 'onAccept' | 'onDismiss' | 'isLoading'>) => {
  const { initDate, endDate } = resume || {}

  return (
    <AlertDialogSlide
      isOpen={isOpen}
      onClose={onDismiss}
      title="Resumo do upload"
      agreeButton={{ text: 'Confirmar', onClick: onAccept }}
      disagreeButton={{ text: 'Cancelar', onClick: () => onDismiss?.(), closeOnClick: true }}
      isLoading={isLoading}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="body1">
          Período:{' '}
          {initDate && endDate && (
            <>
              <strong>{format(new Date(initDate), 'dd/MM/yyyy')}</strong> -{' '}
              <strong>{format(new Date(endDate), 'dd/MM/yyyy')}</strong>
            </>
          )}
        </Typography>

        <Typography variant="body1">
          Total de lançamentos com despesa: <strong>{resume?.totalExpensesTransactions || 0}</strong>
        </Typography>

        <Typography variant="body1">
          Total de lançamentos com receita: <strong>{resume?.totalRevenueTransactions || 0}</strong>
        </Typography>

        <Typography variant="body1">
          Total de lançamentos: <strong>{resume?.totalTransactions || 0}</strong>
        </Typography>

        <Divider />

        <Typography variant="body1">
          Valor total de despesas: <strong>{formatCurrencyBRL(resume?.totalExpenses || 0)}</strong>
        </Typography>

        <Typography variant="body1">
          Valor total de receitas: <strong>{formatCurrencyBRL(resume?.totalRevenue || 0)}</strong>
        </Typography>

        <Typography variant="body1">
          Balanço:{' '}
          <strong>
            {formatCurrencyBRL(resume?.totalRevenue ? resume?.totalRevenue - resume?.totalExpenses : 0)}
          </strong>
        </Typography>
      </Box>
    </AlertDialogSlide>
  )
}
