import { AlertDialogSlide } from '../../Mui/Modal'

export const ModalUpload = ({
  isOpen,
  onClose,
  children
}: {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
}) => {
  return (
    <AlertDialogSlide isOpen={isOpen} onClose={onClose} title="Upload de Extrato">
      {children}
    </AlertDialogSlide>
  )
}
