import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Box, IconButton, Typography } from '@mui/material'
import { useTheme } from '@mui/material'
import { format, parseJSON } from 'date-fns'
import {
  MaterialReactTable,
  MRT_RowSelectionState,
  MRT_ShowHideColumnsButton,
  MRT_SortingState,
  MRT_TablePagination,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable
} from 'material-react-table'
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { SimplifiedLancamento } from '../../../domain/lancamento/lancamento'
import { TipoOrcamento } from '../../../domain/orcamento/Orcamento'
import { useTransactionContext } from '../../../hooks/contexts/transactionContext'
import { setEndOfDay } from '../../../hooks/useDate'
import { setStartOfDay } from '../../../hooks/useDate'
import { useDeleteLancamentos } from '../../../queries/lancamento/mutations'
import { useFamilyLancamentos } from '../../../queries/lancamento/queries'
import { noticeError } from '../../../services/Monitoring'
import { useExtractTableStore } from '../../../store/extract/useExtractStore'
import { useExtractStoreV2 } from '../../../store/extract/useExtractStoreV2'
import Button from '../../Buttons'
import { ExportExtractTableToCSV } from '../../Buttons/ExportExtractTableToCSV'
import { ExportExtractTableToPDF } from '../../Buttons/ExportExtractTableToPDF'
import { ModalDeleteTransaction } from '../../Modal/DeleteTransaction'
import { AutocategorizationSwitch } from '../../UploadExtratoV2/Autocategorization'
import { formatCurrencyBRL } from '../../Utils'
import { useColumns } from './columnsV2'
import { FilterSelectsByCategory, IFilter } from './components/FilterSelectsByCategory'
import * as S from './styles'
import { PropsV2 } from './types'

export const LancamentosTableV2 = ({ familyId, startDate, endDate, refetch }: PropsV2) => {
  const columns = useColumns()
  const { palette } = useTheme()
  const { setEditingEntry } = useTransactionContext()
  const { setPagination, pagination } = useExtractTableStore()
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false)
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [isTogglingAutoCategorization, setIsTogglingAutoCategorization] = useState(false)
  const startDateOfDay = setStartOfDay(new Date(startDate))
  const endDateOfDay = setEndOfDay(new Date(endDate))

  const {
    budgetId: storeBudgetId,
    paymentMethodId: storePaymentMethodId,
    categoryId: storeCategoryId,
    type: storeType
  } = useExtractStoreV2()

  const [categoryFilter, setCategoryFilter] = useState<IFilter>({
    budgetId: storeBudgetId,
    paymentMethodId: storePaymentMethodId,
    categoryId: storeCategoryId,
    type: storeType
  })

  const {
    isDeletingLancamentos,
    isLancamentosDeletionError,
    lancamentosDeletionError,
    resetLancamentosDeletion
  } = useDeleteLancamentos()

  const SORTING_MAP: Record<string, string> = {
    descricao: 'Descrição',
    valor: 'Valor',
    data: 'Data',
    categoriaId: 'Categoria',
    orcamentoId: 'Orçamento',
    meioPagamentoId: 'Método de Pagamento'
  }

  const {
    lancamentos,
    isLancamentosError,
    isLoadingLancamentos,
    lancamentosError,
    refetchLancamentos,
    totalPages,
    isFetchingLancamentos
  } = useFamilyLancamentos(
    {
      familyId,
      start: startDateOfDay,
      end: endDateOfDay,
      page: pagination.pageIndex > 1 ? pagination.pageIndex - 1 : 0,
      size: pagination.pageSize,
      sort: sorting[0]?.id ? SORTING_MAP[sorting[0]?.id] : undefined,
      desc: !sorting[0]?.desc,
      ...categoryFilter,
      // TODO: Refator to use paymentMethodId instead of methodId
      methodId: categoryFilter.paymentMethodId
    },
    { enabled: familyId != null }
  )

  const rows = useMemo(() => lancamentos?.results || [], [lancamentos])
  const [selectedRows, setSelectedRows] = useState<MRT_RowSelectionState>({})
  const selectedRowsLength = useMemo(() => Object.keys(selectedRows).length || 0, [selectedRows])
  const isLoading =
    isLoadingLancamentos || isDeletingLancamentos || isFetchingLancamentos || isTogglingAutoCategorization

  const editLancamento = useCallback(
    (lancamento: SimplifiedLancamento) => {
      if (!lancamento) {
        console.log('[editLancamento, 66] lancamento is null')
        return
      }
      const copy: SimplifiedLancamento & { tipoOrcamento?: TipoOrcamento } = { ...lancamento }
      copy.data = format(parseJSON(lancamento.data), 'dd/MM/yyyy')
      copy.tipoOrcamento = lancamento.tipo
      copy.tipoDeParcela = (copy?.parcelas ?? 0) > 1 ? copy?.tipoDeParcela : 99

      // @ts-expect-error
      setEditingEntry(copy)
    },
    [setEditingEntry]
  )

  const getSelectedRows = useCallback(() => {
    const selectedRowsIndexes = Object.keys(selectedRows)
    const selectedRowsArray = selectedRowsIndexes.map((index) => rows[Number(index)])
    return selectedRowsArray
  }, [rows, selectedRows])

  const TableFooter = (): React.JSX.Element => {
    const { totalDespesas, totalReceitas, totalDividas, totalInvestimentos } = lancamentos || {
      totalDespesas: 0,
      totalReceitas: 0,
      totalDividas: 0,
      totalInvestimentos: 0
    }

    return (
      <>
        <Box
          sx={{
            backgroundColor: palette.primary.light,
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          <Box sx={{ width: '50%', padding: '0.5rem', textAlign: 'center' }}>
            <Typography variant="body2" sx={{ color: palette.common.white }}>
              Total de Receitas: <strong>{formatCurrencyBRL(totalReceitas)}</strong>
            </Typography>
          </Box>
          <Box sx={{ width: '50%', padding: '0.5rem', textAlign: 'center' }}>
            <Typography variant="body2" sx={{ color: palette.common.white }}>
              Total de Despesas: <strong>{formatCurrencyBRL(totalDespesas)}</strong>
            </Typography>
          </Box>
          <Box sx={{ width: '50%', padding: '0.5rem', textAlign: 'center' }}>
            <Typography variant="body2" sx={{ color: palette.common.white }}>
              Total de Dívidas: <strong>{formatCurrencyBRL(totalDividas)}</strong>
            </Typography>
          </Box>
          <Box sx={{ width: '50%', padding: '0.5rem', textAlign: 'center' }}>
            <Typography variant="body2" sx={{ color: palette.common.white }}>
              Total de Investimentos: <strong>{formatCurrencyBRL(totalInvestimentos)}</strong>
            </Typography>
          </Box>
        </Box>

        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <MRT_TablePagination table={table} />
      </>
    )
  }

  const table = useMaterialReactTable({
    data: rows,
    columns,
    // enableColumnFilterModes: true,
    // enableColumnOrdering: true,
    // enableGrouping: true,
    // enableColumnPinning: true,
    // enableFacetedValues: true,
    // enableRowActions: true,
    enableColumnActions: false,
    enableRowSelection: true,
    enablePagination: true,
    pageCount: totalPages,
    manualSorting: true,
    onSortingChange: setSorting,
    state: {
      isLoading,
      rowSelection: selectedRows,
      pagination,
      showSkeletons: isLoading,
      sorting
    },
    enableBottomToolbar: true,
    paginationDisplayMode: 'pages',
    initialState: {
      // enableRowSelection: true,
      // showColumnFilters: true,
      // showGlobalFilter: true,
      columnPinning: {
        left: ['mrt-row-expand', 'mrt-row-select'],
        right: ['mrt-row-actions']
      },
      density: 'compact',
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize
      }
    },
    muiTableFooterProps: {
      sx: {
        '.MuiTableCell-root.MuiTableCell-footer': {
          borderRadius: 0
        }
      }
    },
    renderBottomToolbar: () => <TableFooter />,
    rowCount: lancamentos?.totalResults ?? 0,
    manualPagination: true,
    muiPaginationProps: {
      rowsPerPageOptions: [100, 150, 200, 300],
      page: pagination.pageIndex,
      count: totalPages || 1,
      onChange: (_, newPage) => setPagination({ ...pagination, pageIndex: newPage }),
      showRowsPerPage: true,
      showFirstButton: true,
      showLastButton: true
    },
    onPaginationChange: (updater) => {
      const newPagination = typeof updater === 'function' ? updater(pagination) : updater
      setPagination(newPagination)
    },
    enableDensityToggle: false,
    onRowSelectionChange: setSelectedRows,
    renderTopToolbarCustomActions: () => {
      return (
        <>
          <div
            className="table-title"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0 0.5rem',
              gap: '1rem'
            }}
          >
            <Typography variant="title">Lançamentos</Typography>

            {selectedRowsLength > 0 && (
              <IconButton
                disabled={selectedRowsLength === 0}
                onClick={() => setModalConfirmDelete(true)}
                sx={{
                  fontSize: '0.8rem'
                }}
              >
                <DeleteOutlineIcon />
                <span style={{ fontSize: '0.8rem' }}>
                  {selectedRowsLength
                    ? selectedRowsLength > 1
                      ? `${selectedRowsLength} itens`
                      : '1 item'
                    : ''}
                </span>
              </IconButton>
            )}
          </div>
        </>
      )
    },
    renderToolbarInternalActions: ({ table }) => (
      <>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <MRT_ToggleGlobalFilterButton table={table} />

        <FilterSelectsByCategory onFilterChange={setCategoryFilter} activeFilters={categoryFilter} />

        <AutocategorizationSwitch
          toggleAutocategorization={(isToggling: boolean) => {
            setIsTogglingAutoCategorization(isToggling)
          }}
        />

        <ExportExtractTableToPDF
          familyId={familyId}
          startDate={format(startDate, 'dd_MM_yyyy')}
          endDate={format(endDate, 'dd_MM_yyyy')}
          columns={columns}
          table={table}
        />

        <ExportExtractTableToCSV
          familyId={familyId}
          startDate={format(startDate, 'dd_MM_yyyy')}
          endDate={format(endDate, 'dd_MM_yyyy')}
          columns={columns}
          table={table}
        />
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <MRT_ShowHideColumnsButton table={table} />
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <MRT_ToggleFullScreenButton table={table} />
      </>
    ),
    muiTableBodyCellProps: ({ cell, row }) => ({
      onClick: (event) => {
        const cellId = cell.id.split('_')[1]

        if (['orcamentoId', 'categoriaId'].includes(cellId)) {
          event.stopPropagation()
          event.preventDefault()
          return
        }

        const { original } = row || {}
        editLancamento(original)
      }
    }),
    muiTablePaperProps: {
      sx: {
        margin: 0
      }
    },
    muiTopToolbarProps: {
      sx: {
        '& .MuiBox-root': {
          alignItems: 'center',
          background: '#e4eaf4'
        }
      }
    },
    localization: MRT_Localization_PT_BR
  })

  useEffect(() => {
    if (refetch) {
      refetchLancamentos()
    }
  }, [refetch, refetchLancamentos])

  // TODO: improve error handling
  if (isLancamentosError || isLancamentosDeletionError) {
    const error = lancamentosError || lancamentosDeletionError

    noticeError(error, { familyId, start: startDate, end: endDate })

    return (
      <S.ErrorContainer>
        <br />
        Erro ao carregar tabela :c <br />
        Tente novamente: <br />
        <Button
          text="Tentar novamente."
          onClick={() => {
            refetchLancamentos()
            resetLancamentosDeletion()
          }}
        />
      </S.ErrorContainer>
    )
  }

  return (
    <>
      <div style={{ marginBottom: '5rem' }}>
        <MaterialReactTable table={table} />
      </div>

      {modalConfirmDelete && selectedRowsLength > 0 && (
        <ModalDeleteTransaction
          open={modalConfirmDelete}
          onSuccess={() => {
            setSelectedRows({})
            refetchLancamentos()
            setModalConfirmDelete(false)
          }}
          onClose={() => setModalConfirmDelete(false)}
          data={getSelectedRows()}
        />
      )}
    </>
  )
}
