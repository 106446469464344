import { Card, CardContent, Typography, useTheme } from '@mui/material'
import { useEffect, useMemo } from 'react'
import ApexChart from 'react-apexcharts'

import { ButtonCreateDiagnostic } from '../../components/Modal/Diagnostic'
import { DateRangePickerWithHook } from '../../components/Mui/DatePicker'
import { PageHeader } from '../../components/PageHeader'
// import { RightbarV2 } from '../../components/Rightbar'
import Rightbar from '../../components/Rightbar/RightBar'
import { spinnerShow } from '../../globalValues'
import { spinnerHide } from '../../globalValues'
import { useCoreContext } from '../../hooks/contexts/coreContext/coreProvider'
import { useLoadingScreenContext } from '../../hooks/contexts/loadingScreenContext'
import { formatDateStart } from '../../hooks/useDate'
import { formatDateEnd } from '../../hooks/useDate'
import {
  usePostDashboardOrcamentoRealizado,
  usePostTipoOrcamentoPrevistoRealizado
} from '../../queries/dashboard'
import * as S from './styles'
import { useChartOrcamentoPrevistoRealizado } from './useChartOrcamentoPrevistoRealizado'
import { useChartTipoOrcamentoPrevistoRealizado } from './useChartTipoOrcamentoPrevistoRealizado'

export const HomePlannedView = () => {
  const { palette } = useTheme()
  const { familiaId, startDate, endDate } = useCoreContext()
  const { setIsLoading } = useLoadingScreenContext()
  const {
    postTipoOrcamentoPrevistoRealizadoAsync,
    orcamentoPrevistoRealizado,
    isLoadingPostTipoOrcamentoPrevistoRealizado
  } = usePostTipoOrcamentoPrevistoRealizado()
  const {
    postDashboardOrcamentoRealizadoAsync,
    data: orcamentoRealizado,
    isLoadingPostDashboardOrcamentoRealizado
  } = usePostDashboardOrcamentoRealizado()

  const filter = useMemo(() => {
    return {
      familyId: familiaId ?? undefined,
      filter: {
        DataInicial: formatDateStart(new Date(startDate)),
        DataFinal: formatDateEnd(new Date(endDate))
      }
    }
  }, [familiaId, startDate, endDate])

  const nomesTipoOrcamento = orcamentoPrevistoRealizado?.map((orcamento) => orcamento.nome)
  const tiposOrcamentoEstimados = orcamentoPrevistoRealizado?.map((orcamento) => orcamento.estimado)
  const tiposOrcamentoRealizados = orcamentoPrevistoRealizado?.map((orcamento) => orcamento.totalMedio)

  const chartTipoOrcamentoPrevistoRealizado = useChartTipoOrcamentoPrevistoRealizado({
    palette,
    nomesTipoOrcamento: nomesTipoOrcamento ?? [],
    tiposOrcamentoEstimados: tiposOrcamentoEstimados ?? [],
    tiposOrcamentoRealizados: tiposOrcamentoRealizados ?? []
  })

  const chartOrcamentoRealizado = useChartOrcamentoPrevistoRealizado({
    orcamentoRealizado: orcamentoRealizado ?? []
  })

  useEffect(() => {
    postTipoOrcamentoPrevistoRealizadoAsync(filter)
    postDashboardOrcamentoRealizadoAsync(filter)
  }, [filter, postTipoOrcamentoPrevistoRealizadoAsync, postDashboardOrcamentoRealizadoAsync])

  useEffect(() => {
    if (isLoadingPostTipoOrcamentoPrevistoRealizado || isLoadingPostDashboardOrcamentoRealizado) {
      setIsLoading(true)
      spinnerShow($)
    } else {
      setIsLoading(false)
      spinnerHide($)
    }
  }, [isLoadingPostTipoOrcamentoPrevistoRealizado, isLoadingPostDashboardOrcamentoRealizado, setIsLoading])

  useEffect(() => {
    const toggleRightSidebar = () => {
      const rightSidebar = document.getElementById('toggle-rightbar')
      if (rightSidebar) {
        rightSidebar.click()
      }
    }

    toggleRightSidebar()
  }, [])

  useEffect(() => {
    const windowResize = () => {
      window.addEventListener('resize', () => {
        const rightSidenavMain = document.getElementById('right-sidenav-main')
        const isClosed = rightSidenavMain?.classList.contains('closed')
        const screenWidth = window.innerWidth
        const buttonRightbar = document.getElementById('toggle-rightbar')

        if (!isClosed && screenWidth < 1200) {
          buttonRightbar?.click()
        }
      })
    }

    windowResize()

    return () => {
      window.removeEventListener('resize', windowResize)
    }
  }, [])

  return (
    <>
      <S.Container className="home-planned-view home-index">
        <PageHeader title="Home" icon="dashboard">
          <ButtonCreateDiagnostic />
        </PageHeader>

        <div>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
              marginBottom: '1rem'
            }}
          >
            <DateRangePickerWithHook label="Período" />

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '10px'
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: '0.8rem',
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '5px'
                }}
              >
                <i className="fas fa-chart-bar" style={{ color: palette.secondary.main }} />
                <strong className="chart-title" style={{ lineHeight: 1 }}>
                  Estimado
                </strong>
              </p>

              <p
                style={{
                  margin: 0,
                  fontSize: '0.8rem',
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '5px'
                }}
              >
                <i className="fas fa-chart-bar" style={{ color: palette.primary.main }} />
                <strong className="chart-title" style={{ lineHeight: 1 }}>
                  Realizado
                </strong>
              </p>
            </div>
          </div>

          <ApexChart
            options={chartTipoOrcamentoPrevistoRealizado.options as ApexCharts.ApexOptions}
            series={
              chartTipoOrcamentoPrevistoRealizado.series as ApexAxisChartSeries | ApexNonAxisChartSeries
            }
            width="100%"
            height="350px"
            type="bar"
            className="home-estimate-realized"
            style={{ marginLeft: '-20px' }}
          />
        </div>

        <Card>
          <CardContent style={{ padding: '2rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <img
                src={require('../../assets/img/theme/icone-analytics.png')}
                alt="Orçamentos Realizados"
                className="before-title-img before-title-img-2"
              />
              <Typography variant="h6">Orçamentos Realizados</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className="chart-expense-adjust" style={{ width: 500 }}>
                <ApexChart
                  options={chartOrcamentoRealizado.options as unknown as ApexCharts.ApexOptions}
                  series={chartOrcamentoRealizado.series as ApexAxisChartSeries | ApexNonAxisChartSeries}
                  className="chart-expense-2"
                  type="donut"
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </S.Container>
      {/*  */}
      {/* <RightbarV2 /> */}
      <Rightbar
        // @ts-ignore
        logo={{
          innerLink: '/admin/home',
          imgSrc: require('../../assets/img/brand/logo-nova-branca.png'),
          imgAlt: '...'
        }}
        collapseOpen={true}
        id="right-sidenav-main"
        style={familiaId === null ? { display: 'none' } : {}}
        position="fixed-right"
        menuItens={['sumarioPrevistoRealizadoGeral', 'ultimosLancamentos', 'gastosPorCategoria']}
      />
    </>
  )
}
