import NewspaperIcon from '@mui/icons-material/Newspaper'
import { IconButton, styled, Tooltip } from '@mui/material'
import React, { useState } from 'react'

import ModalPortal from '../Modal/ModalPortal'
import NotesModal from '../Modal/NotesModal'

// import styled from 'styled-components'

// const Button = styled.button`
//   width: 40px;
//   height: 40px;
//   border: none;
//   padding: 0;
//   background: transparent;
//   transition: all 0.2s ease-in-out;
//   position: relative;
//   cursor: pointer;
//   img {
//     width: 40px;
//     height: 40px;
//   }
//   &::after {
//     transition: all 0.2s ease-in-out;
//     content: 'Bloco de Notas';
//     position: absolute;
//     bottom: unset;
//     top: -80px;
//     left: -64px;
//     width: 160px;
//     transform: scale(0.8);
//     z-index: 999;
//     padding: 4px;
//     font-size: 13px;
//     font-weight: 700;
//     border-radius: 40px;
//     background-color: ${({ theme }) => theme.colors.primary};
//     color: #fff;
//     opacity: 0;
//   }
//   &:hover {
//     &::after {
//       top: unset;
//       bottom: -40px;
//       transform: scale(1);
//       opacity: 1;
//     }
//   }
// `

const StyledIconButton = styled(IconButton)`
  && {
    background: ${({ theme }) => theme.palette.secondary.main};
    svg {
      fill: ${({ theme }) => theme.palette.getContrastText(theme.palette.secondary.main)};
      font-size: 1.1rem;
    }

    &:hover {
      background: ${({ theme }) => theme.palette.secondary.dark};
      svg {
        fill: ${({ theme }) => theme.palette.getContrastText(theme.palette.secondary.dark)};
      }
    }
  }
`

export default () => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Tooltip title="Bloco de Notas" arrow placement="left">
        <StyledIconButton onClick={handleOpen}>
          <NewspaperIcon />
        </StyledIconButton>
      </Tooltip>

      <ModalPortal isOpen={open}>
        <NotesModal open={open} onClose={handleClose} />
      </ModalPortal>
    </>
  )
}
