import { Cookies } from 'react-cookie'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { logger } from '../logger/logger'
import { type AuthState, type AuthStore } from './types'

const initialState: AuthState = {
  isAuthenticated: false
}

export const MAX_AGE = 60 * 60 * 24 // 24hs

export const cookies = new Cookies()
export const setCookie = (name: string, value: string, options?: object) => {
  cookies.set(name, value, {
    path: '/',
    maxAge: MAX_AGE, // 24hs
    ...options
  })
}

export const removeCookie = (name: string, options?: object) => {
  cookies.remove(name, {
    path: '/',
    ...options
  })
}

export const getCookie = (name: string) => {
  return cookies.get(name)
}

export const resetAuthCookies = () => {
  removeCookie('TOKEN', { path: '/' })
  sessionStorage.clear()
  localStorage.removeItem('jwt')
  window.location.reload()
}

export const useAuthStore = create<AuthStore>()(
  persist(
    logger<AuthStore>((set, _get) => {
      return {
        ...initialState,
        setJwt: (jwt: string) => {
          const token = cookies.get('TOKEN')
          localStorage.setItem('jwt', jwt)
          setCookie(
            'TOKEN',
            {
              ...token,
              jwt
            },
            { path: '/', maxAge: MAX_AGE, secure: true }
          )
        },
        setIsAuthenticated: ({ jwt, token }) => {
          const data = {
            isAuthenticated: !!jwt,
            token,
            jwt
          }

          set(() => data)

          setCookie('TOKEN', JSON.stringify(data), { path: '/', secure: true })
        },
        getToken: () => {
          const token = getCookie('TOKEN')
          return token || initialState
        },
        reset: () => {
          set(initialState)
          resetAuthCookies()
        }
      }
    }, 'authStore'),
    {
      name: 'authStore',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
)
