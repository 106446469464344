import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import * as React from 'react'
import { useEffect, useState } from 'react'

import { ButtonDefault } from '../Button'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const AlertDialogSlide = ({
  agreeButton,
  disagreeButton,
  isOpen,
  onClose,
  title,
  children,
  isLoading,
  size = 'sm'
}: {
  agreeButton?: {
    text: string
    onClick: () => void
    closeOnClick?: boolean
  }
  disagreeButton?: {
    text: string
    onClick: () => void
    closeOnClick?: boolean
  }
  isOpen: boolean
  onClose?: () => void
  title?: string
  children: React.ReactNode
  isLoading?: boolean
  size?: 'sm' | 'md' | 'lg'
}) => {
  const [open, setOpen] = useState(isOpen)

  const handleClose = () => {
    setOpen(false)
    onClose?.()
  }

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth={size}
        fullWidth
      >
        {title && <DialogTitle sx={{ margin: '0 0 1rem' }}>{title}</DialogTitle>}
        <DialogContent>{children}</DialogContent>

        {(agreeButton || disagreeButton) && (
          <DialogActions sx={{ padding: '1rem', justifyContent: 'space-between' }}>
            {disagreeButton && (
              <ButtonDefault
                disabled={isLoading}
                loading={isLoading}
                onClick={() => {
                  disagreeButton.onClick()
                  if (disagreeButton.closeOnClick) {
                    handleClose()
                  }
                }}
              >
                {disagreeButton.text}
              </ButtonDefault>
            )}

            {agreeButton && (
              <ButtonDefault
                disabled={isLoading}
                loading={isLoading}
                onClick={() => {
                  agreeButton.onClick()
                  if (agreeButton.closeOnClick) {
                    handleClose()
                  }
                }}
                variant="contained"
              >
                {agreeButton.text}
              </ButtonDefault>
            )}
          </DialogActions>
        )}
      </Dialog>
    </>
  )
}
