import React, { useEffect } from 'react'

import { useBankPdfTypes } from '../../../queries/extrato/queries'
import { SelectAutoComplete } from '../../Mui/SelectAutoComplete'

interface Props {
  onChange: (type: string) => void
  currentBank?: string
  className?: string
}

export const ExtractOriginSelect = ({ onChange, currentBank }: Props) => {
  const typesPDF = useBankPdfTypes(currentBank)

  const singleOption = typesPDF.length === 1
  useEffect(() => {
    if (!singleOption) return
    onChange(typesPDF[0].value)
  }, [onChange, singleOption, typesPDF])

  return (
    <SelectAutoComplete<{ label: string; value: string }>
      label="Tipos de PDF"
      options={typesPDF}
      disabled={!typesPDF || typesPDF.length === 0}
      onChange={(option) => {
        if (option) onChange?.(option.value)
      }}
      currentSelected={currentBank}
    />
  )
}
