import React, { useMemo } from 'react'

import { SelectAutoComplete } from '../../../Mui/SelectAutoComplete'

export const SelectBudgetType = ({
  currentSelected,
  onChange,
  size = 'medium'
}: {
  currentSelected?: string
  onChange: (option?: { value: string; label: string } | null | undefined) => void
  size?: 'small' | 'medium'
}) => {
  const tipoOrcamentos = useMemo(
    () => [
      { value: 'Receita', label: 'Receita' },
      { value: 'Despesa', label: 'Despesa' },
      { value: 'Investimento', label: 'Investimento' },
      { value: 'Divida', label: 'Divida' }
    ],
    []
  )
  const placeholder = tipoOrcamentos.find(({ value }) => value === currentSelected)?.label || 'Selecione...'

  return (
    <SelectAutoComplete<{ value: string; label: string }>
      placeholder={placeholder}
      options={tipoOrcamentos}
      onChange={onChange}
      size={size}
      currentSelected={currentSelected}
    />
  )
}
