import 'dayjs/locale/pt-br'

import {
  endOfDay,
  endOfMonth,
  isAfter,
  isBefore,
  isValid,
  startOfDay,
  startOfMonth,
  subMonths,
  subYears
} from 'date-fns'
import dayjs from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'

import { useCoreContext } from '../../../hooks/contexts/coreContext/coreProvider'
import { CustomActionBar } from './Shortcuts/Shortcuts'
import { TDateRangePickerActions } from './Shortcuts/types'
import * as S from './styles'

export const DATE_RANGE_PICKER_ACTIONS: TDateRangePickerActions[] = [
  'today',
  'currentMonth',
  'oneMonth',
  'threeMonths',
  'sixMonths'
]
export const DATE_RANGE_PICKER_ACTIONS_MAP = {
  today: 'Hoje',
  currentMonth: 'Mês atual',
  oneMonth: '1 mês',
  threeMonths: '3 meses',
  sixMonths: '6 meses'
}
export const DATE_RANGE_PICKER_ACTIONS_MAP_KEYS = Object.keys(DATE_RANGE_PICKER_ACTIONS_MAP)
export type TDateRangePickerActionsMapKeys = keyof typeof DATE_RANGE_PICKER_ACTIONS_MAP

interface IDatePickerMui {
  className?: string
  defaultValue?: string
  label?: string
  loading?: boolean
  name?: string
  onChange?: (date: Date) => void
  onSetOneMonth?: () => void
  onSetThreeMonths?: () => void
  onSetSixMonths?: () => void
  onSetOneYear?: () => void
  onSetToday?: () => void
  onSetCurrentMonth?: () => void
  value?: string
  activeFilter?: TDateRangePickerActions
}

export const DatePickerMui = ({
  className,
  defaultValue,
  label,
  loading = false,
  name,
  onChange,
  onSetThreeMonths,
  onSetSixMonths,
  onSetOneYear,
  onSetCurrentMonth,
  onSetOneMonth,
  onSetToday,
  value,
  activeFilter
}: IDatePickerMui) => {
  return (
    <S.DatePickerMui
      className={className}
      defaultValue={defaultValue ? dayjs(defaultValue) : dayjs(new Date())}
      format="DD/MM/YYYY"
      name={name}
      label={label}
      loading={loading}
      localeText={{
        clearButtonLabel: 'Limpar',
        todayButtonLabel: 'Hoje'
      }}
      value={value ? dayjs(value) : dayjs(new Date())}
      onChange={(dayjs) => dayjs && onChange?.(dayjs.toDate())}
      slots={{
        shortcuts: (props) => (
          <CustomActionBar
            {...props}
            actions={DATE_RANGE_PICKER_ACTIONS}
            onSetThreeMonths={onSetThreeMonths}
            onSetSixMonths={onSetSixMonths}
            onSetOneYear={onSetOneYear}
            onSetCurrentMonth={onSetCurrentMonth}
            onSetToday={onSetToday}
            onSetOneMonth={onSetOneMonth}
            activeFilter={activeFilter}
          />
        )
      }}
      slotProps={{
        textField: {
          variant: 'filled'
        }
      }}
    />
  )
}

const getTodayIsLastDayOfMonth = (date: Date) => {
  const lastDayOfMonth = endOfMonth(date)
  const today = new Date()
  return lastDayOfMonth.getDate() === today.getDate()
}

export const DateRangePickerWithHook = ({
  label,
  onChangeStartDate,
  onChangeEndDate
}: {
  label?: string
  onChangeStartDate?: (date: Date) => void
  onChangeEndDate?: (date: Date) => void
}) => {
  const { endDate, startDate, setStartDate, setEndDate } = useCoreContext()
  const [activeFilter, setActiveFilter] = useState<TDateRangePickerActions>()
  const today = useMemo(() => new Date(), [])

  const todayIsLastDayOfMonth = useMemo(() => {
    return getTodayIsLastDayOfMonth(today)
  }, [today])

  const onSetToday = () => {
    setStartDate(startOfDay(today))
    setEndDate(endOfDay(today))
    setActiveFilter('today')
  }

  const onSetCurrentMonth = () => {
    setStartDate(startOfMonth(today))
    setEndDate(endOfMonth(today))
    setActiveFilter('currentMonth')
  }

  const onSetOneMonth = () => {
    if (todayIsLastDayOfMonth) {
      setStartDate(startOfMonth(today))
      setEndDate(endOfMonth(today))
      setActiveFilter('oneMonth')
    } else {
      const startMonth = subMonths(startOfDay(today), 1)
      const endMonth = subMonths(endOfDay(today), 1)
      setStartDate(startOfMonth(startMonth))
      setEndDate(endOfMonth(endMonth))
      setActiveFilter('oneMonth')
    }
  }

  const onSetThreeMonths = () => {
    if (todayIsLastDayOfMonth) {
      const startMonth = subMonths(startOfDay(today), 2)
      setStartDate(startOfMonth(startMonth))
      setEndDate(endOfMonth(today))
      setActiveFilter('threeMonths')
    } else {
      const startMonth = subMonths(startOfDay(today), 3)
      const endMonth = subMonths(endOfDay(today), 1)
      setStartDate(startOfMonth(startMonth))
      setEndDate(endOfMonth(endMonth))
      setActiveFilter('threeMonths')
    }
  }

  const onSetSixMonths = () => {
    if (todayIsLastDayOfMonth) {
      const startMonth = subMonths(startOfDay(today), 5)
      setStartDate(startOfMonth(startMonth))
      setEndDate(endOfMonth(today))
      setActiveFilter('sixMonths')
    } else {
      const startMonth = subMonths(startOfDay(today), 6)
      const endMonth = subMonths(endOfDay(today), 1)
      setStartDate(startOfMonth(startMonth))
      setEndDate(endOfMonth(endMonth))
      setActiveFilter('sixMonths')
    }
  }

  useEffect(() => {
    if (startDate) {
      onChangeStartDate?.(startOfDay(new Date(startDate)))
      document.getElementById('rightbar-startdate-refresh')?.click()
    }
    if (endDate) {
      onChangeEndDate?.(endOfDay(new Date(endDate)))
      document.getElementById('rightbar-enddate-refresh')?.click()
    }
  }, [startDate, endDate, onChangeStartDate, onChangeEndDate])

  return (
    <S.DatePickerRangeContainer>
      {label && <S.DatePickerRangeTitle variant="body1">{label}</S.DatePickerRangeTitle>}
      <DatePickerMui
        className="mui-date-picker"
        defaultValue={startDate}
        name="startDate"
        onChange={(value) => {
          const isValidDate = isValid(value)
          const morethanYearsAgo = isBefore(value, subYears(today, 10))
          const lessThanYearsAgo = isAfter(value, subYears(today, 10))
          if (isValidDate && !morethanYearsAgo && lessThanYearsAgo) {
            setStartDate(new Date(value))
          }
        }}
        value={startDate}
        onSetThreeMonths={onSetThreeMonths}
        onSetSixMonths={onSetSixMonths}
        onSetOneMonth={onSetOneMonth}
        onSetCurrentMonth={onSetCurrentMonth}
        onSetToday={onSetToday}
        activeFilter={activeFilter}
      />
      <DatePickerMui
        className="mui-date-picker"
        defaultValue={endDate}
        name="endDate"
        onChange={(value) => {
          const isValidDate = isValid(value)
          const morethanYearsAgo = isBefore(value, subYears(today, 10))
          const lessThanYearsAgo = isAfter(value, subYears(today, 10))
          if (isValidDate && !morethanYearsAgo && lessThanYearsAgo) {
            setEndDate(new Date(value))
          }
        }}
        value={endDate}
        onSetThreeMonths={onSetThreeMonths}
        onSetSixMonths={onSetSixMonths}
        onSetOneMonth={onSetOneMonth}
        onSetCurrentMonth={onSetCurrentMonth}
        onSetToday={onSetToday}
        activeFilter={activeFilter}
      />
    </S.DatePickerRangeContainer>
  )
}
