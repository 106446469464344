import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Box, Chip, Stack, styled } from '@mui/material'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useCoreContext } from '../../hooks/contexts/coreContext/coreProvider'
import { useConfirmUpload, useExtratoUpload } from '../../queries/extrato/mutations'
import { useBankPdfTypes } from '../../queries/extrato/queries'
import { ButtonGradient } from '../Mui/Button'
import { DialogExtractResume, DialogExtractUploadSuccess } from './SuccessfulUploadAlert'
import { UploadErrorAlert, UploadExtractErrorAlert } from './UploadErrorAlert'

export type TFileFormat = 'ofx' | 'csv' | 'xls' | 'pdf' | 'xlsx'
const FILE_FORMATS_MAP: Record<TFileFormat, string> = {
  ofx: '.ofx',
  csv: '.csv',
  xls: '.xls, .xlsx',
  pdf: '.pdf',
  xlsx: '.xlsx, .xls'
}

interface Props {
  meioPagamentoId?: number
  formato?: TFileFormat
  banco?: string
  typePdf?: string
  year?: number
  onUpload?: (file: File) => void
  disabled?: boolean
  onResetFile?: () => void
  onSuccess?: () => void
}

const VisuallyHiddenInput = styled('input')({
  bottom: 0,
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  left: 0,
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: 1
})

export const FileUploadForExtrato = ({
  banco,
  formato,
  meioPagamentoId,
  typePdf,
  year,
  onUpload,
  disabled,
  onResetFile,
  onSuccess
}: Props) => {
  const { familiaId } = useCoreContext()
  const { empresaId } = useCoreContext()
  const [file, setFile] = useState<File | null>(null)
  const [isResumeOpen, setIsResumeOpen] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false)

  const {
    confirmUpload,
    isLoadingConfirmUpload,
    isConfirmUploadError,
    isConfirmUploadSuccess,
    confirmUploadResponse,
    invalidateQueries
  } = useConfirmUpload()

  const {
    isExtratoUploadError,
    isUploading,
    resetUpload,
    sendToExtrato,
    sendToVistaExtract,
    uploadError,
    uploaded,
    resume
  } = useExtratoUpload()
  const isLoading = isUploading || isLoadingConfirmUpload

  const uploadExtrato = useCallback(
    (uploadEvent: ChangeEvent<HTMLInputElement>) => {
      const bancoEditado = (rawBank: string) => rawBank.split('_')[0]
      const paymentMethodEditado = (rawPay: string) => rawPay.split('_')[1]

      if (!familiaId || !empresaId || !meioPagamentoId || !formato || !banco) {
        console.warn('missing data')
        return
      }

      if (!uploadEvent?.target?.files?.[0]) {
        console.warn('no files uploaded')
        return
      } else {
        setFile(uploadEvent.target.files[0])
      }

      const file = uploadEvent.target.files[0]
      const originalFile = file
      const reader = new FileReader()

      reader.onload = (fileReadEvent) => {
        fileReadEvent?.preventDefault()
        if (!fileReadEvent?.target?.result) return
        const binaryString = fileReadEvent.target.result
        const file = btoa(binaryString.toString())

        onUpload?.(originalFile)

        if (banco === 'excelPadrao_excelPadrao' && empresaId) {
          sendToVistaExtract({
            spreadsheetBase64: file,
            companyId: String(empresaId),
            familyId: String(familiaId),
            paymentMethodId: meioPagamentoId
          })
        } else if (empresaId) {
          sendToExtrato({
            file,
            type: formato,
            bank: bancoEditado(banco),
            paymentMethod: paymentMethodEditado(banco),
            empresaId: String(empresaId),
            familiaId: String(familiaId),
            meioPagamentoId: meioPagamentoId,
            typePdf,
            descriptionYear: year
          })
        }
      }
      reader.readAsBinaryString(file)
    },
    [
      banco,
      empresaId,
      familiaId,
      formato,
      meioPagamentoId,
      onUpload,
      sendToExtrato,
      sendToVistaExtract,
      typePdf,
      year
    ]
  )

  const hasMainData = meioPagamentoId != null && formato != null && banco != null
  const requiresPdfType = useBankPdfTypes(banco).length > 1
  const canUpload = (hasMainData && (!requiresPdfType || typePdf !== undefined)) || !disabled

  const clearUpload = useCallback(() => {
    setFile(null)
    resetUpload()
    fileInputRef?.current?.setAttribute('value', '')
    onResetFile?.()
  }, [resetUpload, onResetFile, fileInputRef])

  const handleResumeAccept = useCallback(() => {
    if (resume?.id) {
      confirmUpload({ id: resume?.id })
      setIsResumeOpen(false)
    }
  }, [confirmUpload, resume])

  const handleResumeDismiss = useCallback(() => {
    setIsResumeOpen(false)
  }, [])

  const FileInputHidden = useMemo(() => {
    return (
      <VisuallyHiddenInput
        ref={fileInputRef}
        type="file"
        accept={formato ? FILE_FORMATS_MAP[formato] : ''}
        name="file"
        onInput={uploadExtrato}
        disabled={!canUpload}
      />
    )
  }, [canUpload, formato, uploadExtrato, fileInputRef])

  const handleUploadSuccess = useCallback(() => {
    invalidateQueries()
    clearUpload()
    setIsSuccessDialogOpen(false)
    onSuccess?.()
  }, [invalidateQueries, clearUpload, onSuccess])

  const { exception, errorMsg } = confirmUploadResponse ?? {
    exception: [],
    errorMsg: ''
  }

  useEffect(() => {
    if (fileInputRef.current && file) {
      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(file)
      fileInputRef.current.files = dataTransfer.files
    }
  }, [file])

  useEffect(() => {
    if (uploaded) {
      setIsResumeOpen(true)
    }
  }, [uploaded])

  useEffect(() => {
    if (exception?.length > 0) {
      setIsSuccessDialogOpen(true)
      return
    }

    if (isConfirmUploadSuccess) {
      handleUploadSuccess()
    }
  }, [isConfirmUploadSuccess, handleUploadSuccess, confirmUploadResponse, exception, errorMsg])

  return (
    <>
      <Box display="flex" gap={2} flexDirection="column">
        {file && (
          <Stack direction="row" spacing={2}>
            <Chip label={file.name} onDelete={clearUpload} />
          </Stack>
        )}
        <UploadErrorAlert isError={isExtratoUploadError} error={uploadError} onAccept={clearUpload} />
      </Box>

      <div>
        <ButtonGradient
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          type="button"
          startIcon={<CloudUploadIcon />}
          loading={isLoading}
          disabled={!canUpload}
          style={{ marginBottom: '1rem' }}
        >
          Selecionar arquivo
          {FileInputHidden}
        </ButtonGradient>
      </div>

      <DialogExtractResume
        isOpen={isResumeOpen}
        resume={resume}
        onAccept={handleResumeAccept}
        onDismiss={handleResumeDismiss}
        isLoading={isLoading}
      />

      <DialogExtractUploadSuccess
        isOpen={isSuccessDialogOpen}
        exception={exception}
        errorMsg={errorMsg}
        onAccept={handleUploadSuccess}
        isLoading={isLoading}
      />

      <UploadExtractErrorAlert isError={isConfirmUploadError} error={uploadError} onAccept={clearUpload} />
    </>
  )
}
