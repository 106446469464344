import { formatDateEnd, formatDateStart } from '../../../hooks/useDate'
import { noticeError } from '../../../services/Monitoring'
import { getWithToken, getWithTokenApiV2, postWithToken } from '../../../webClient'
import { ManyResults } from '../../Results'
import { Lancamento, SimplifiedLancamento } from '../lancamento'
// Todo: type filters when necessary
interface Filter {}

export const fetchByFamily = async (familyId: number, filter: Filter) => {
  const url = `lancamento/familia/${familyId}/filtro`
  const {
    data: { results: lancamentos, success, errorMsg, totalResults },
    status
  } = await postWithToken<ManyResults<Lancamento>, Filter>(url, filter)
  if (!success || status !== 200) {
    const msg = `Error getting lancamentos for family ${familyId} with filters ${JSON.stringify(filter)}`
    console.error(msg, errorMsg)
    throw new Error(msg)
  }

  return { lancamentos, totalResults }
}

export interface FetchSimplifiedResponse {
  totalDespesas: number
  totalReceitas: number
  totalInvestimentos: number
  totalDividas: number
  pageIndex: number
  pageSize: number
  totalResults: number
  results: SimplifiedLancamento[]
}

export interface IFamiliesResponse {
  id?: number
  nome?: string
  statusFamilia?: number
  planejadorId?: number
  planejadorNome?: string
  empresaId?: number
  empresaNome?: string
}

export const getFamiliesApi = async () => {
  const url = 'api/v2/familia'
  const { data: response, status } = await getWithTokenApiV2<IFamiliesResponse[]>(url)

  if (status !== 200) {
    const message = `Error getting families ${status}`
    console.error(message)
    const error = new Error(message)
    noticeError(error)
    throw error
  }
  return response
}

const removeUndefinedValues = (params: Record<string, string | number | boolean | undefined>) => {
  return Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== undefined && value !== '' && value !== null && value !== 0
    )
  )
}

export const fetchSimplifiedByFamily = async (
  familyId: number,
  start: Date,
  end: Date,
  description: string | undefined = '',
  pageIndex: number,
  pageSize: number,
  orderBy?: string,
  desc?: boolean,
  meioPagamentoId?: number,
  orcamentoId?: number,
  categoriaId?: number,
  tipo?: string,
  pluggyInvestmentId?: string
) => {
  const url = `lancamento/v2/familia/${familyId}/tabela`
  const params = {
    pageSize,
    pageIndex,
    description,
    start: formatDateStart(start),
    end: formatDateEnd(end),
    orderBy,
    desc,
    meioPagamentoId,
    orcamentoId,
    categoriaId,
    tipo,
    pluggyInvestmentId
  }

  const filteredParams = removeUndefinedValues(params)

  const { data: response, status } = await getWithToken<FetchSimplifiedResponse>(url, {
    params: filteredParams
  })
  if (status !== 200) {
    const message = `Error getting simple lancamentos for family ${familyId} ${status}`
    console.error(message, filteredParams)
    const error = new Error(message)
    noticeError(error, filteredParams)
    throw error
  }
  return response
}
