import { SimplifiedCategoria } from '../categoria/Categoria'

export default interface Orcamento {
  OrcamentoId: number
  CategoriaId: number
  TipoOrcamento: number
  tipoOrcamento?: number
}

export enum TipoOrcamento {
  Receita,
  Despesa,
  Investimento,
  Divida,
  InvestimentoResgate,
  Desconhecido = 999 // Frontend only
}

export const BudgetType = {
  [TipoOrcamento.Receita]: 'Receita',
  [TipoOrcamento.Despesa]: 'Despesa',
  [TipoOrcamento.Investimento]: 'Investimento',
  [TipoOrcamento.Divida]: 'Divida',
  [TipoOrcamento.InvestimentoResgate]: 'Investimento Resgate'
}

export type TipoOrcamentoKeys = keyof typeof TipoOrcamento

export interface SimplifiedOrcamento {
  id: number
  name: string
  tipo: TipoOrcamento
}

export interface SimplifiedOrcamentoWithCategory {
  id: number
  nome: string
  tipo: TipoOrcamento
  categorias: SimplifiedCategoria[]
  suspenso: boolean
}

export const getTipoKey = (value?: number): TipoOrcamentoKeys => {
  if (value === undefined || value === null) return 'Desconhecido'
  const parsed = TipoOrcamento[value]
  return parsed as TipoOrcamentoKeys
}

export const isExpense = (type: TipoOrcamento) =>
  type === TipoOrcamento.Despesa || type === TipoOrcamento.Divida
