import { TipoOrcamento } from '../orcamento/Orcamento'

export interface Lancamento {
  // TODO: Type more props, the API returns way more data
  id: number
  empresaId: number
  familiaId: number
}

export interface SimplifiedLancamento {
  categoriaId: number
  categoriaPadrao: boolean
  data: string // UTC-3 date string such as '2022-06-01T00:00:00'
  dateQuota?: string
  descricao: string
  financialInstitutionCode: string | null
  id: number
  isManual?: boolean
  meioPagamentoId: number
  orcamentoId: number
  orcamentoPadrao: boolean
  parcelado: boolean
  parcelas?: number
  pluggyInvestmentId: number
  pluggyItemId: number
  pluggyTransactionId: number
  precoCota?: number
  quantidadeCota?: number
  taxes?: number
  tipo: TipoOrcamento
  tipoDeParcela?: 0 | 1 | 99 | null
  valor: number
  valorParcela?: number
}

export const getLoadingSimplifiedLancamento = (): SimplifiedLancamento => ({
  id: 0,
  data: new Date().toISOString(),
  descricao: '--- --- --- --- ---',
  valor: Number((Math.random() * 1000 * 1000).toFixed(2)),
  meioPagamentoId: 0,
  categoriaId: 0,
  orcamentoId: 0,
  categoriaPadrao: false,
  orcamentoPadrao: false,
  pluggyInvestmentId: 0,
  pluggyItemId: 0,
  pluggyTransactionId: 0,
  parcelado: false,
  tipo: TipoOrcamento.Receita,
  financialInstitutionCode: null
})

export type TTransactionTypes = 0 | 1 | 99
export type TTransactionTypeName = 'parcelado' | 'repetido' | 'unico'

export const TRANSACTION_CODE_NAME: { [key in TTransactionTypes]: TTransactionTypeName } = {
  0: 'parcelado',
  1: 'repetido',
  99: 'unico'
}

export const TRANSACTION_NAME_CODE: { [key in TTransactionTypeName]: TTransactionTypes } = {
  parcelado: 0,
  repetido: 1,
  unico: 99
}

export const getTransactionType = ({
  transactionType = 99
}: {
  transactionType?: TTransactionTypes | null
}) => {
  if (typeof transactionType !== 'number' || transactionType === 99)
    return { isSingle: true, isRepeated: false, isParceled: false }

  const isRepeated = TRANSACTION_CODE_NAME[transactionType] === 'repetido'
  const isParceled = TRANSACTION_CODE_NAME[transactionType] === 'parcelado'
  const isSingle = TRANSACTION_CODE_NAME[transactionType] === 'unico'

  return {
    isRepeated,
    isParceled,
    isSingle
  }
}
export const getTransactionTypeFromArray = ({ transactions }: { transactions: SimplifiedLancamento[] }) => {
  const hasParceled =
    transactions?.find((item) => item?.parcelas && item?.parcelas > 1 && item?.tipoDeParcela === 0) ?? false
  const hasRepeated =
    transactions?.find((item) => item?.parcelas && item?.parcelas > 1 && item?.tipoDeParcela === 1) ?? false

  return {
    hasRepeated,
    hasParceled
  }
}
