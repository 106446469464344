import { noticeError } from '../../../services/Monitoring'
import { getWithToken, putWithToken } from '../../../webClient'
import { Family } from '../Family'

export interface Props {
  enabled: boolean
  familyId: number
}

export const toggleFamilyAutoCategorization = async ({ enabled, familyId }: Props) => {
  const url = `v2/familia/${familyId}/autocategorization/${enabled}`
  const { data: family, status } = await putWithToken<Family, void>(url)

  if (status !== 200) {
    const msg = `Error toggling family ${familyId} auto categorization ${enabled}`
    const error = new Error(msg)
    noticeError(error, { familyId })
    throw error
  }

  return family
}

export const autocategorizationAPI = async ({ familyId }: { familyId: number }) => {
  const url = `v2/familia/${familyId}/autocategorization/`
  const { data, status } = await getWithToken<{ autoCategorization: boolean }>(url)

  if (status !== 200) {
    const msg = `Error getting family ${familyId} autocategorization`
    const error = new Error(msg)
    noticeError(error, { familyId })
  }

  return {
    data,
    status
  }
}
