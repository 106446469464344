import React, { useMemo, useState } from 'react'
import { PluginHook, useGlobalFilter, useSortBy, useTable } from 'react-table'

import { getCheckboxColumn } from '../columns'
import TableLoading from '../TableLoading'
import TablePagination from '../TablePagination'
import TableRow from '../TableRow'
import TableRowNull from '../TableRowNull'
import { HeaderCell } from './components/HeaderCell'
import { TableTitle } from './components/TableTitle'
import * as S from './styles'
import { TableProps } from './types'

export default <T extends object>({
  columns,
  data,
  defaultFilter,
  deletable = false,
  downloadable = true,
  downloadFileName,
  downloadFormat,
  filterableColumnNames = [],
  filterDefaultSelected,
  filterEnabled = true,
  isLoading = false,
  isToggleSwitchChecked,
  loadingData,
  mapDownloadFile,
  onAllRowsSelection,
  onAllRowsUnselection,
  onColumnFilterChange = () => {},
  onDelete = () => {},
  onFilterChange,
  onNextPage,
  onPageSizeChange,
  onPreviousPage,
  onRowClick,
  onRowSelection,
  onRowUnselection,
  onSort,
  onToggleSwitch,
  pageable = false,
  pageIndex,
  pageSize,
  printable = true,
  refetchData,
  renderExpanded,
  renderFooter,
  rowsSelectable = false,
  selectedRows,
  sortable = true,
  title,
  toggleableColumns = true,
  toggledOffByDefaultColumns = [],
  toggleSwitchButton = false,
  toggleSwitchText,
  totalPages,
  widthBeforeHorizontalScroll = 1350
}: TableProps<T>) => {
  const isExpandable = !!renderExpanded
  const [expandedRowsIndexes, setExpandedRowsIndexes] = useState<number[]>([])
  const isRowExpanded = (index: number) => expandedRowsIndexes?.includes(index)
  const expandRow = (index: number) => setExpandedRowsIndexes(expandedRowsIndexes?.concat(index))
  const contractRow = (index: number) =>
    setExpandedRowsIndexes(expandedRowsIndexes?.filter((i) => i !== index))
  const onExpandClick = (index: number) => (isRowExpanded(index) ? contractRow(index) : expandRow(index))
  const contractAllRows = () => setExpandedRowsIndexes([])

  const plugins: PluginHook<T>[] = []
  if (filterEnabled) plugins.push(useGlobalFilter)
  if (sortable) plugins.push(useSortBy)

  const [hiddenColumns, setHiddenColumns] = useState(toggledOffByDefaultColumns)
  const displayedColumns = useMemo(() => {
    const displayed = columns?.filter(({ id }) => !hiddenColumns.includes(id || ''))
    if (rowsSelectable && displayed?.[0])
      displayed?.unshift(
        getCheckboxColumn({
          rows: data,
          selected: selectedRows,
          onHeaderSelect: (isSelected) =>
            isSelected ? onAllRowsUnselection?.(data) : onAllRowsSelection?.(data),
          onSelect: onRowSelection,
          onDeselect: onRowUnselection
        })
      )
    return displayed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columns,
    // data,
    hiddenColumns,
    // onAllRowsSelection,
    // onAllRowsUnselection,
    // onRowSelection,
    // onRowUnselection,
    rowsSelectable,
    selectedRows
  ])

  const displayedData = useMemo(() => {
    if (isLoading && loadingData) return Array(pageSize || 10).fill(loadingData) as T[]
    return data
  }, [isLoading, loadingData, data, pageSize])

  const tableInstance = useTable(
    {
      data: displayedData || [],
      columns: displayedColumns || [],
      autoResetSortBy: onSort === undefined,
      manualSortBy: onSort !== undefined
    },
    ...plugins
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state: { globalFilter }
  } = tableInstance

  const filterProps = {
    setFilter: (filter?: string) => {
      setGlobalFilter(filter)
      onFilterChange?.(filter)
    },
    filter: globalFilter
  }

  return (
    <S.ScrollContainer widthBeforeScroll={widthBeforeHorizontalScroll} className="table-scroll-container">
      <S.Container widthBeforeScroll={widthBeforeHorizontalScroll}>
        {isLoading && <TableLoading />}
        <TableTitle
          refetchData={refetchData}
          data={displayedData}
          title={title}
          filterEnabled={filterEnabled}
          filterProps={filterProps}
          downloadable={downloadable}
          downloadFormat={downloadFormat}
          mapDownloadFile={mapDownloadFile}
          deletable={deletable}
          onDelete={onDelete}
          selectedRowsCount={selectedRows?.length}
          downloadFileName={downloadFileName}
          printable={printable}
          toggleableColumns={toggleableColumns}
          disabledColumnNames={hiddenColumns}
          columns={columns}
          defaultFilter={defaultFilter}
          onHiddenColumnsChange={setHiddenColumns}
          columnNames={filterableColumnNames}
          onFilterChange={onColumnFilterChange}
          toggleSwitchButton={toggleSwitchButton}
          onToggleSwitch={onToggleSwitch}
          toggleSwitchText={toggleSwitchText}
          isToggleSwitchChecked={isToggleSwitchChecked}
          filterDefaultSelected={filterDefaultSelected}
        />

        <S.TableContainer widthBeforeScroll={widthBeforeHorizontalScroll}>
          <S.Table {...getTableProps()}>
            <S.Header>
              {headerGroups.map((headerGroup) => (
                <S.HeaderRow {...headerGroup.getHeaderGroupProps()} onClick={contractAllRows}>
                  {isExpandable && <S.ExpanderHeadCell />}
                  {headerGroup.headers.map((column, index) => (
                    <HeaderCell column={column} sortable={sortable} onSort={onSort} key={`header_${index}`} />
                  ))}
                </S.HeaderRow>
              ))}
            </S.Header>
            <S.Body {...getTableBodyProps()}>
              {rows?.length > 0 ? (
                rows?.map((row, rowIndex) => {
                  // console.log({ row, rowIndex }, `${title}_row_${rowIndex}`)
                  if (!row) return null
                  const expand = () => onExpandClick(rowIndex)
                  prepareRow?.(row)

                  return (
                    <TableRow
                      key={`${title}_row_${rowIndex}`}
                      row={row}
                      index={rowIndex}
                      renderExpanded={renderExpanded}
                      isRowExpanded={isRowExpanded(rowIndex)}
                      onExpand={expand}
                      onClick={onRowClick}
                    />
                  )
                })
              ) : (
                <TableRowNull index={2} text={'Nenhum Registro Encontrado'} />
              )}
            </S.Body>
          </S.Table>
        </S.TableContainer>

        {renderFooter?.(data)}

        {pageable && (
          <TablePagination
            pageIndex={pageIndex ?? 0}
            pageSize={pageSize ?? displayedData.length}
            totalPages={totalPages ?? 0}
            onNextPage={() => {
              onNextPage?.()
              contractAllRows()
            }}
            onPreviousPage={() => {
              onPreviousPage?.()
              contractAllRows()
            }}
            onPageSizeChange={(newSize) => {
              onPageSizeChange?.(newSize)
              contractAllRows()
            }}
          />
        )}
      </S.Container>
    </S.ScrollContainer>
  )
}
