import React, { useMemo } from 'react'

import { useBankAndTypes } from '../../../queries/extrato/queries'
import { SelectAutoComplete } from '../../Mui/SelectAutoComplete'

export type OnChangeBankParam = {
  value?: string
  label: string
}

interface Props {
  onChange?: (banco?: OnChangeBankParam | null) => void
  className?: string
  disabled?: boolean
  value?: OnChangeBankParam['value']
}

export const BancsSelect = ({ onChange, disabled, value }: Props) => {
  const { banksAndTypes } = useBankAndTypes()

  const banks = useMemo(() => {
    return (
      banksAndTypes?.map(({ bank, label, types }) => ({
        value: bank,
        label: label.replace('- undefined', ' '),
        types
      })) || []
    )
  }, [banksAndTypes])

  const defaultValueVistaPattern = useMemo(
    () => banks?.find((bank) => bank.label === 'Excel Padrão Vista')?.value,
    [banks]
  )

  return (
    <SelectAutoComplete<OnChangeBankParam>
      label="Instiuição Financeira"
      placeholder="Conta Corrente / Cartão de Crédito"
      options={banks}
      defaultValue={defaultValueVistaPattern as OnChangeBankParam['value']}
      disabled={disabled}
      currentSelected={value}
      onChange={(selectedValue) => {
        onChange?.(selectedValue)
      }}
    />
  )
}
