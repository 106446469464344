import FilterListIcon from '@mui/icons-material/FilterList'
import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  Link,
  Menu,
  styled,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'

import { ExtractStoreV2Keys, useExtractStoreV2 } from '../../../../store/extract/useExtractStoreV2'
import { FilterByBudget } from './FilterByBudget'
import { FilterByCategory } from './FilterByCategory'
import { FilterByPaymentMethod } from './FilterByPaymentMethod'
import { SelectBudgetType } from './SelectBudgetType'

const checkIfHasAnyValue = (filter: object) => {
  return Object.values(filter).some((value) => !!value)
}

export interface IFilter {
  type?: string
  categoryId?: number
  paymentMethodId?: number
  budgetId?: number
}

export const DEFAULT_FILTER: IFilter = {
  type: undefined,
  categoryId: undefined,
  paymentMethodId: undefined,
  budgetId: undefined
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    color: theme.palette.text.secondary,
    fontSize: '0.8rem',
    fontWeight: 'bold'
  }
}))

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.8rem',
  fontWeight: 'bold'
}))

export const FilterSelectsByCategory = ({
  onFilterChange,
  activeFilters
}: {
  onFilterChange?: (filter: IFilter) => void
  activeFilters?: IFilter
}) => {
  const { palette } = useTheme()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [hasAnyValue, setHasAnyValue] = useState(false)
  const [filter, setFilter] = useState<IFilter>(activeFilters ?? DEFAULT_FILTER)

  const {
    set: setFilterStore,
    budgetId: storeBudgetId,
    paymentMethodId: storePaymentMethodId,
    categoryId: storeCategoryId,
    type: storeType,
    reset: resetFilterStore
  } = useExtractStoreV2()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const onChangeValue = ({
    name,
    option
  }: {
    name: ExtractStoreV2Keys
    option: { value: string | number; label: string } | null | undefined
  }) => {
    const value = option?.value
    const newFilter = { ...filter, [name]: value }
    const setFilterStoreFunction = setFilterStore[name]

    if (setFilterStoreFunction) setFilterStoreFunction(value as never)

    setFilter(newFilter)
    onFilterChange?.(newFilter)
  }

  const handleCheckIfHasAnyValue = useCallback(() => {
    const hasAnyValue = checkIfHasAnyValue(filter)
    setHasAnyValue(hasAnyValue)
  }, [filter])

  const handleSetFilter = useCallback(() => {
    setFilter({
      budgetId: storeBudgetId,
      paymentMethodId: storePaymentMethodId,
      categoryId: storeCategoryId,
      type: storeType
    })
  }, [storeBudgetId, storePaymentMethodId, storeCategoryId, storeType])

  const handleResetFilter = useCallback(() => {
    resetFilterStore()
    setFilter(DEFAULT_FILTER)
    onFilterChange?.(DEFAULT_FILTER)
  }, [resetFilterStore, onFilterChange])

  useEffect(() => {
    handleSetFilter()
  }, [handleSetFilter])

  useEffect(() => {
    handleCheckIfHasAnyValue()
  }, [handleCheckIfHasAnyValue])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        position: 'relative'
      }}
    >
      <Tooltip title="Filtrar por categoria">
        <IconButton
          onClick={handleClick}
          sx={{
            color: hasAnyValue ? 'primary.main' : 'inherit',
            backgroundColor: hasAnyValue ? '#0000000a' : 'inherit'
          }}
        >
          <FilterListIcon sx={{ color: hasAnyValue ? palette.primary.light : 'inherit' }} />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setOpen(false)}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        sx={{
          '& .MuiPaper-root': {
            padding: '0 0.7rem'
          }
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #0000001a', pb: 1 }}>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
            >
              Filtros
            </Typography>
          </Box>

          <StyledFormControl size="small">
            <StyledFormLabel>Categoria</StyledFormLabel>
            <FilterByCategory
              onChange={(option) => onChangeValue({ name: 'categoryId', option })}
              size="small"
              currentSelected={filter?.categoryId}
            />
          </StyledFormControl>

          <StyledFormControl size="small">
            <StyledFormLabel>Meio de pagamento</StyledFormLabel>
            <FilterByPaymentMethod
              onChange={(option) => onChangeValue({ name: 'paymentMethodId', option })}
              size="small"
              currentSelected={filter?.paymentMethodId}
            />
          </StyledFormControl>

          <StyledFormControl size="small">
            <StyledFormLabel>Tipo de orçamento</StyledFormLabel>
            <SelectBudgetType
              currentSelected={filter?.type}
              size="small"
              onChange={(option) => onChangeValue({ name: 'type', option })}
            />
          </StyledFormControl>

          <StyledFormControl size="small">
            <StyledFormLabel>Orçamento</StyledFormLabel>
            <FilterByBudget
              onChange={(option) => onChangeValue({ name: 'budgetId', option })}
              size="small"
              currentSelected={filter?.budgetId}
            />
          </StyledFormControl>

          {hasAnyValue && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                borderTop: '1px solid #0000001a',
                pt: 1,
                alignItems: 'center'
              }}
            >
              <Link
                onClick={handleResetFilter}
                variant="caption"
                color="text.secondary"
                sx={{ cursor: 'pointer', '&:hover': { color: palette.primary.main } }}
              >
                Limpar filtros
              </Link>
            </Box>
          )}
        </Box>
      </Menu>
    </Box>
  )
}
