import React from 'react'

import { MeioPagamento } from '../../../domain/meioPagamento/meioPagamento'
import { useCoreContext } from '../../../hooks/contexts/coreContext/coreProvider'
import { useFamilyMeiosPagamentos } from '../../../queries/meioPagamento/queries'
import { SelectAutoComplete } from '../../Mui/SelectAutoComplete'

interface Props {
  onChange?: (meio?: MeioPagamento['id']) => void
  placeholder?: string
  grayscale?: boolean
  selectedId?: MeioPagamento['id'] | undefined
  className?: string
}

interface IPaymentMethodSelectOption {
  value: number
  label: string
}

export const PaymentMethodSelect = ({ onChange, selectedId }: Props) => {
  const { familiaId: familyId } = useCoreContext()
  const { meiosPagamentos: meios, isLoadingMeios } = useFamilyMeiosPagamentos(familyId ? familyId : undefined)
  const options2 = meios?.map(({ id, nome }) => ({ value: id, label: nome })) || []

  return (
    <>
      <SelectAutoComplete<IPaymentMethodSelectOption>
        options={options2}
        onChange={(option) => {
          if (onChange && option?.value) onChange(option?.value)
        }}
        defaultValue={selectedId}
        currentSelected={selectedId}
        label="Meio de pagamento"
        isLoading={isLoadingMeios}
      />
    </>
  )
}
