import MotionPhotosAutoIcon from '@mui/icons-material/MotionPhotosAuto'
import { IconButton, Skeleton } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { useCallback, useEffect } from 'react'

import { useCoreContext } from '../../../hooks/contexts/coreContext/coreProvider'
import { useAutocategorization, useToggleFamilyAutoCategorization } from '../../../queries/family/mutations'

export const AutocategorizationSwitch = ({
  toggleAutocategorization
}: {
  toggleAutocategorization: (isToggling: boolean) => void
}) => {
  const { familiaId: familyId } = useCoreContext()
  const {
    toggleFamilyAutoCategorization,
    isTogglingAutoCategorization,
    isSuccessToggleFamilyAutoCategorization
  } = useToggleFamilyAutoCategorization()
  const { getAutocategorizationAsync, autocategorizationData } = useAutocategorization()

  const handleToggleAutocategorization = useCallback(() => {
    toggleFamilyAutoCategorization({
      enabled: !autocategorizationData?.autoCategorization,
      familyId: Number(familyId)
    })
  }, [autocategorizationData, familyId, toggleFamilyAutoCategorization])

  useEffect(() => {
    toggleAutocategorization(isTogglingAutoCategorization)
  }, [toggleAutocategorization, isTogglingAutoCategorization])

  useEffect(() => {
    getAutocategorizationAsync({ familyId: Number(familyId) })
  }, [getAutocategorizationAsync, familyId])

  useEffect(() => {
    if (isSuccessToggleFamilyAutoCategorization) {
      getAutocategorizationAsync({ familyId: Number(familyId) })
    }
  }, [isSuccessToggleFamilyAutoCategorization, familyId, getAutocategorizationAsync])

  return (
    <Tooltip
      title={
        autocategorizationData?.autoCategorization
          ? 'Desativar categorização automática'
          : 'Ativar categorização automática'
      }
    >
      <IconButton onClick={handleToggleAutocategorization} disabled={isTogglingAutoCategorization}>
        {isTogglingAutoCategorization ? (
          <Skeleton variant="circular" width={20} height={20} />
        ) : (
          <MotionPhotosAutoIcon color={autocategorizationData?.autoCategorization ? 'success' : 'action'} />
        )}
      </IconButton>
    </Tooltip>
  )
}
