import { useCoreContext } from '../../../../hooks/contexts/coreContext/coreProvider'
import { useFamilyOrcamentosForTable } from '../../../../queries/orcamento/queries'
import { SelectAutoComplete } from '../../../Mui/SelectAutoComplete'

interface Option {
  label: string
  value: number
}

interface FilterByBudgetProps {
  onChange?: (option?: Option | null) => void
  size?: 'small' | 'medium'
  currentSelected?: number
}

export const FilterByBudget = ({ onChange, size = 'medium', currentSelected }: FilterByBudgetProps) => {
  const { familiaId: familyId } = useCoreContext()
  const { orcamentos, isLoadingOrcamentos } = useFamilyOrcamentosForTable(familyId ?? undefined)

  const options =
    orcamentos
      ?.filter(({ suspenso }) => !suspenso)
      ?.map(({ id, nome }) => ({
        value: id,
        label: nome
      })) ?? []

  return (
    <SelectAutoComplete<Option>
      options={options}
      size={size}
      isLoading={isLoadingOrcamentos}
      onChange={(option) => {
        onChange?.(option)
      }}
      currentSelected={currentSelected}
    />
  )
}
