import React, { useEffect, useState } from 'react'

import { FileType } from '../../../domain/extrato/BankAndType'
import { useBankAndTypes } from '../../../queries/extrato/queries'
import { SelectAutoComplete } from '../../Mui/SelectAutoComplete'

interface Props {
  onChange: (value?: FileType) => void
  currentBank?: string
  className?: string
  disabled?: boolean
}

export const FilesTypesExtractSelect = ({ onChange, currentBank, disabled }: Props) => {
  const { banksAndTypes } = useBankAndTypes()
  const [value, setValue] = useState<FileType['value']>()
  const getBankFormats = (bank?: string) =>
    banksAndTypes?.find((fullBank) => fullBank.bank === bank)?.types ?? []
  const formats = getBankFormats(currentBank)
    ?.sort((a, b) => a.label.localeCompare(b.label))
    ?.map((format) => ({
      value: format.value,
      label: format.label.toUpperCase()
    }))

  const singleOption = formats.length === 1
  useEffect(() => {
    if (!singleOption) return
    onChange(formats[0])
  }, [formats, onChange, singleOption])

  return (
    <SelectAutoComplete<{ value: string; label: string }>
      label="Formato"
      value={value}
      options={formats.map((format) => ({ value: format.value, label: format.label }))}
      disabled={!formats || formats.length <= 1 || disabled}
      currentSelected={value}
      onChange={(option) => {
        if (option) {
          onChange({ value: option.value, label: option.label } as FileType)
        } else {
          onChange(undefined)
        }
        setValue(option?.value)
      }}
    />
  )
}
