import React, { useMemo, useState } from 'react'

import { SimplifiedCategoria } from '../../../domain/categoria/Categoria'
import { useObjectLocalStorage } from '../../../hooks'
import { useCoreContext } from '../../../hooks/contexts/coreContext/coreProvider'
import { useFamilyOrcamentosForTable } from '../../../queries/orcamento/queries'
import { SelectAutoComplete } from '../../Mui/SelectAutoComplete'
import { LancamentoEdit } from '../../Tables/LancamentosTable/columns'

interface Props {
  lancamentoId: number
  onSelect?: (lancamentoId: number, orcamentoId: number, categoriaId: number) => void
  defaultPlaceholder?: string
  orcamentoId?: number
  size?: 'small' | 'medium'
}

export const CategoriaSelectV2 = ({
  lancamentoId,
  defaultPlaceholder,
  onSelect,
  orcamentoId,
  size
}: Props) => {
  const { familiaId: familyId } = useCoreContext()
  const [filter, setFilter] = useState('')
  const { value: edited } = useObjectLocalStorage<LancamentoEdit>('table_l_edits', {})
  const { orcamentos, isLoadingOrcamentos } = useFamilyOrcamentosForTable(familyId ?? undefined)
  const selectedOrcamentoId = edited[lancamentoId]?.orcamentoId || orcamentoId || 0

  const options = useMemo(
    () =>
      selectedOrcamentoId
        ? (
            orcamentos
              ?.find((o) => o.id === selectedOrcamentoId)
              ?.categorias?.map((c) => ({ value: c, text: c.nome }))
              ?.filter(({ text }) => text && text.toLowerCase().includes(filter.toLowerCase())) ?? []
          ).map(({ value, text }) => ({ value, label: text }))
        : [],
    [filter, orcamentos, selectedOrcamentoId]
  )

  return (
    <SelectAutoComplete<{ value: SimplifiedCategoria; label: string }>
      size={size}
      isLoading={isLoadingOrcamentos}
      placeholder={defaultPlaceholder || 'Selecione...'}
      options={options}
      onChange={(option) => {
        setFilter('')
        onSelect?.(lancamentoId, selectedOrcamentoId, option?.value.id ?? 0)
      }}
    />
  )
}
