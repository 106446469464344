import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import * as S from './styles'

interface ModalPortalProps {
  children: React.ReactNode
  isOpen: boolean
}

const ModalPortal: React.FC<ModalPortalProps> = ({ children, isOpen }) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])

  if (!mounted) return null

  return createPortal(
    <S.ModalContainer style={{ display: isOpen ? 'block' : 'none' }}>
      {children}
    </S.ModalContainer>,
    document.body
  )
}

export default ModalPortal 