import styled from 'styled-components'

import Colors from '../../domain/Colors'

export const SpanTextDefault = styled.span`
  line-height: 1;
  display: block;
  position: absolute;
  top: -12px;
  background-color: #fff;
  padding: 6px;
  font-size: 14px;
  margin-left: 8px;
  font-weight: 500;
  color: #32325d;
`

export const Wrapper = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const ContainerBetaTag = styled.div`
  position: absolute;
  top: -12px;
  left: 144px;
  z-index: 100;
`

export const HeaderGroup = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  padding: 4px 8px 4px 8px;
  width: 100%;
`

export const ContainerSelect = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  padding: 4px 8px 4px 8px;
  width: 100%;

  div[class*='ValueContainer'] {
    padding: 0 0 0 4px;
    height: 28px !important;
  }
  .meio-pagamento-select,
  .year-select {
    width: 200px;
    border: 1px solid ${Colors.purple};
    padding: 0 !important;
    line-height: 21px;
    font-size: 14px;
    height: 30px;
  }
  div[class$='-container'] {
    width: 200px;
    padding: 0px !important;
  }
  div[class$='control'] {
    border-radius: 16px !important;
    padding: 0 4px 0 4px !important;
    min-height: 24px !important;
  }
  div[class$='placeholder'] {
    font-size: 14px;
  }
  div[class$='input'] {
    height: 24px !important;
  }
  div[class$='indicatorContainer'] {
    padding: 0 4px 0 4px !important;
  }
  div[class$='IndicatorsContainer'] {
    padding: 0 !important;
    margin: 0 !important;
  }
`

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  padding: 4px 8px 4px 8px;
  width: 100%;
`

export const ButtonGroup = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`
