import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'

import { SimplifiedCategoria } from '../../../domain/categoria/Categoria'
import { SimplifiedLancamento } from '../../../domain/lancamento/lancamento'
import { useGetFilteredCategories } from '../../../queries/categoria/queries'
import { useChangeLancamentoCategory } from '../../../queries/lancamento/mutations'
import { noticeError } from '../../../services/Monitoring'
import { CategoriaSelectV2 } from '../../Selects/CategoriaSelectV2'

interface Props {
  selectedId: SimplifiedCategoria['id']
}

const Text = styled.span`
  text-align: center;
`

export const CategoryCell = ({ selectedId }: Props) => {
  const { category, isLoadingCategorias } = useGetFilteredCategories({ categoriaId: selectedId })

  const text = useCallback(() => {
    if (isLoadingCategorias) {
      return '...'
    }

    let name = 'Sem categoria'
    if (category?.nome && selectedId) {
      name = category?.nome
    }

    return name
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCategorias, selectedId])

  return <Text>{text()}</Text>
}

export const CategorySelectCell = ({ id, onCategorization, selectedRows, lancamentoId, tipo }) => {
  const { changeCategory, isCategoryChangeError, changedCategory, resetChangeCategory } =
    useChangeLancamentoCategory()

  useEffect(() => {
    if (isCategoryChangeError)
      noticeError(new Error('Error changing category'), {
        orcamentoId: id,
        lancamentoId,
        tipo
      })
  }, [isCategoryChangeError, id, lancamentoId, tipo])

  useEffect(() => {
    if (changedCategory) resetChangeCategory()
  }, [changedCategory, resetChangeCategory])

  const CategoriaField = useCallback(() => {
    return (
      <CategoriaSelectV2
        size="small"
        lancamentoId={lancamentoId}
        onSelect={(lancamentoId, _orcamentoId, idCategoria) => {
          changeCategory({ id: lancamentoId, idCategoria })
          selectedRows?.forEach(({ id }) => changeCategory({ id, idCategoria }))
          onCategorization?.()
        }}
      />
    )
  }, [changeCategory, lancamentoId, onCategorization, selectedRows])

  return <CategoriaField />
}

export const CategoryCellMemoized = ({
  id,
  categoriaPadrao,
  onCategorization,
  selectedRows,
  lancamentoId,
  tipo,
  orcamentoId,
  onSelectOrcamento
}: {
  id: SimplifiedCategoria['id']
  categoriaPadrao: boolean
  onCategorization: () => void
  selectedRows: SimplifiedLancamento[]
  lancamentoId: SimplifiedLancamento['id']
  tipo: SimplifiedLancamento['tipo']
  orcamentoId: SimplifiedLancamento['orcamentoId']
  onSelectOrcamento?: (props: {
    lancamentoId: SimplifiedLancamento['id']
    idCategoria: SimplifiedCategoria['id']
  }) => void
}) => {
  const { changeCategory, isCategoryChangeError, changedCategory, resetChangeCategory } =
    useChangeLancamentoCategory()

  useEffect(() => {
    if (isCategoryChangeError)
      noticeError(new Error('Error changing category'), {
        orcamentoId: id,
        lancamentoId,
        tipo
      })
  }, [isCategoryChangeError, id, lancamentoId, tipo])

  useEffect(() => {
    if (changedCategory) resetChangeCategory()
  }, [changedCategory, resetChangeCategory])

  const CategoriaField = useCallback(() => {
    return (
      <CategoriaSelectV2
        size="small"
        lancamentoId={lancamentoId}
        orcamentoId={orcamentoId}
        onSelect={(lancamentoId, _orcamentoId, idCategoria) => {
          changeCategory({ id: lancamentoId, idCategoria })
          selectedRows?.forEach(({ id }) => changeCategory({ id, idCategoria }))
          onCategorization?.()
          onSelectOrcamento?.({ lancamentoId, idCategoria })
        }}
      />
    )
  }, [changeCategory, lancamentoId, onCategorization, onSelectOrcamento, selectedRows, orcamentoId])

  if (categoriaPadrao) {
    return <CategoriaField />
  }

  return <CategoryCell selectedId={id} />
}
