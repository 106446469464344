import { useMutation, useQuery } from 'react-query'

import {
  getDashboardLastEntriesApi,
  postDashboardCategoriaPrevistoRealizadoMesApi,
  postDashboardOrcamentoPeriodoByFamilyIdApi
} from '../../domain/dashboard/api'

export const cacheBaseKey = 'dashboard'

export const usePostDashboardOrcamentoPeriodoByFamilyIdMutation = () => {
  const {
    mutateAsync: postDashboardOrcamentoPeriodoByFamilyIdAsync,
    isLoading: isLoadingPostDashboardOrcamentoPeriodoByFamilyId,
    isError: isErrorPostDashboardOrcamentoPeriodoByFamilyId,
    error: postDashboardOrcamentoPeriodoByFamilyIdError,
    isSuccess: postDashboardOrcamentoPeriodoByFamilyIdSuccess,
    reset: resetPostDashboardOrcamentoPeriodoByFamilyId,
    data
  } = useMutation(postDashboardOrcamentoPeriodoByFamilyIdApi, {
    retry: false
  })

  return {
    postDashboardOrcamentoPeriodoByFamilyIdAsync,
    isLoadingPostDashboardOrcamentoPeriodoByFamilyId,
    isErrorPostDashboardOrcamentoPeriodoByFamilyId,
    postDashboardOrcamentoPeriodoByFamilyIdError,
    postDashboardOrcamentoPeriodoByFamilyIdSuccess,
    resetPostDashboardOrcamentoPeriodoByFamilyId,
    data
  }
}

export const usePostDashboardCategoriaPrevistoRealizadoMes = () => {
  const {
    mutateAsync: postDashboardCategoriaPrevistoRealizadoMesAsync,
    isLoading: isLoadingPostDashboardCategoriaPrevistoRealizadoMes,
    isError: isErrorPostDashboardCategoriaPrevistoRealizadoMes,
    error: postDashboardCategoriaPrevistoRealizadoMesError,
    isSuccess: postDashboardCategoriaPrevistoRealizadoMesSuccess,
    reset: resetPostDashboardCategoriaPrevistoRealizadoMes,
    data
  } = useMutation(postDashboardCategoriaPrevistoRealizadoMesApi, {
    retry: false
  })

  return {
    postDashboardCategoriaPrevistoRealizadoMesAsync,
    isLoadingPostDashboardCategoriaPrevistoRealizadoMes,
    isErrorPostDashboardCategoriaPrevistoRealizadoMes,
    postDashboardCategoriaPrevistoRealizadoMesError,
    postDashboardCategoriaPrevistoRealizadoMesSuccess,
    resetPostDashboardCategoriaPrevistoRealizadoMes,
    data
  }
}

export const useGetDashboardLastEntries = ({
  familyId,
  quantidade
}: {
  familyId?: number
  quantidade?: number
}) => {
  const {
    data,
    isLoading: isLoadingLastEntries,
    isError: isErrorLastEntries,
    error: errorLastEntries
  } = useQuery(
    'dashboard-last-entries',
    () => (familyId ? getDashboardLastEntriesApi({ familyId, quantidade }) : null),
    {
      retry: 2,
      refetchOnWindowFocus: false
    }
  )

  return {
    data,
    isLoadingLastEntries,
    isErrorLastEntries,
    errorLastEntries
  }
}
